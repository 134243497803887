<template>
  <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="maintItemDetail">
    <template slot="adSearch">
      <vm-search :label="$t('maintWorkOrder.workCondition')">
        <el-select v-model="search.workCondition" :placeholder="$t('common.pleaseSelect')" clearable>
          <el-option v-for="item in workConditionOption" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
      </vm-search>
      <vm-search :label="$t('maintWorkOrder.maintArea')">
        <el-select v-model="search.maintArea" :placeholder="$t('common.pleaseSelect')" clearable>
          <el-option v-for="item in maintAreaOption" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
      </vm-search>
    </template>
    <table-column prop="maintContent" :label="$t('maintWorkOrder.maintContent')"></table-column>
    <table-column prop="maintArea" :label="$t('maintWorkOrder.maintArea')" width="100px" align="center">
      <template #default="scope">
        <span v-if="scope.row.maintArea === '机房'">
          {{ $t("maintWorkOrder.maintenanceArea.machineRoom") }}
        </span>
        <span v-else-if="scope.row.maintArea === '轿厢'">
          {{ $t("maintWorkOrder.maintenanceArea.bridge") }}
        </span>
        <span v-else-if="scope.row.maintArea === '层站'">
          {{ $t("maintWorkOrder.maintenanceArea.layer") }}
        </span>
        <span v-else-if="scope.row.maintArea === '井道及底坑'">
          {{ $t("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit") }}
        </span>
        <span v-else-if="scope.row.maintArea === '扶梯'">
          {{ $t("maintWorkOrder.maintenanceArea.escalator") }}
        </span>
        <span v-else-if="scope.row.maintArea === '其他'">
          {{ $t("maintWorkOrder.maintenanceArea.other") }}
        </span>
      </template>
    </table-column>
    <table-column prop="workCondition" :label="$t('maintWorkOrder.workCondition')" align="center"
      :width="this.$l('200px', '100px')">
      <template #default="scope">
        <el-tag v-if="scope.row.workCondition === null" type="info">
          {{ $t("maintWorkOrder.condition.notProcessed") }}
        </el-tag>
        <el-tag v-else-if="scope.row.workCondition === 0" type="warning">
          {{ $t("maintWorkOrder.condition.noSuchItem") }}
        </el-tag>
        <el-tag v-else-if="scope.row.workCondition === 1" type="success">
          {{ $t("maintWorkOrder.condition.qualified") }}
        </el-tag>
        <el-tag v-else-if="scope.row.workCondition === 2">
          {{ $t("maintWorkOrder.condition.qualifiedAfterAdjustment") }}
        </el-tag>
        <el-tag v-else-if="scope.row.workCondition === 3" type="danger">
          {{ $t("maintWorkOrder.condition.unqualified") }}
        </el-tag>
      </template>
    </table-column>
    <table-column prop="situationDescription" :label="$t('maintWorkOrder.situationDescription')"></table-column>
    <table-column prop="picture" :label="$t('maintWorkOrder.picture')" align="center" width="100px">
      <template #default="scope">
        <el-image v-if="scope.row.picture && scope.row.picture.includes('uploadFiles')"
          style="width: 25px; height: auto" :src="handleOldPhoto(scope.row)" fit="contain" lazy
          :preview-src-list="[handleOldPhoto(scope.row)]">
        </el-image>
        <el-image v-else-if="scope.row.picture" style="width: 25px; height: auto" :src="scope.row.picture" fit="contain"
          lazy :preview-src-list="[scope.row.picture]">
        </el-image>
      </template>
    </table-column>
    <table-column prop="soundRecord" :label="$t('maintWorkOrder.soundRecord')" align="center">
      <template #default="scope">
        <audio v-if="scope.row.soundRecord" style="width: 100%; height: 30px" :src="scope.row.soundRecord"
          controls="controls">
        </audio>
      </template>
    </table-column>
    <table-column prop="processTime" :label="$t('maintWorkOrder.processTime')" width="150px"
      align="center"></table-column>
  </vm-table>
</template>

<script>
export default {
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      search: {
        id: this.id,
        maintArea: "",
        workCondition: "",
      },
      maintAreaOption: [
        { value: "机房", label: this.$t("maintWorkOrder.maintenanceArea.machineRoom") },
        { value: "轿厢", label: this.$t("maintWorkOrder.maintenanceArea.bridge") },
        { value: "层站", label: this.$t("maintWorkOrder.maintenanceArea.layer") },
        { value: "井道及底坑", label: this.$t("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit") },
        { value: "扶梯", label: this.$t("maintWorkOrder.maintenanceArea.escalator") },
        { value: "其他", label: this.$t("maintWorkOrder.maintenanceArea.other") },
      ],
      workConditionOption: [
        { value: 0, label: this.$t("maintWorkOrder.condition.noSuchItem") },
        { value: 1, label: this.$t("maintWorkOrder.condition.qualified") },
        { value: 2, label: this.$t("maintWorkOrder.condition.qualifiedAfterAdjustment") },
        { value: 3, label: this.$t("maintWorkOrder.condition.unqualified") },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
      this.loading = false;
    },
    handleOldPhoto(row) {
      return window.config.SERVER_URL + row.picture;
    },
  },
};
</script>

<style scoped></style>